.loaderBigWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 30vh;
}

.loaderSmallWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}