@font-face {
  font-family: 'SF Pro';
  src: url('SFProDisplay-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'SF Pro';
  src: url('SFProDisplay-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'SF Pro';
  src: url('SFProDisplay-Semibold.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'SF Pro';
  src: url('SFProDisplay-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro';
  src: url('SFProDisplay-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'SF Pro';
  src: url('SFProDisplay-Black.ttf');
  font-weight: 800;
}
