@import "uikit/themes/styles/fonts/googleSans/stylesheet.css";
@import "uikit/themes/styles/fonts/publicSans/index.css";
@import "uikit/themes/styles/fonts/SFPro/sfPro";

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'SF Pro', Roboto, sans-serif;
  box-sizing: border-box;
  color: #041121;
}

html {
  height: 100%;
}

#root{
  height: 100%;
}

body {
  height: 100%;
}

input, button, img, textarea {
  border: none;
  outline: none;
}

ul {
  list-style: none;
}

th {
  font-weight: 400;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Roboto";
}

.react-tooltip-lite {
  background-color: #F4F7FA;
  color: #79858F;
  box-shadow: 0 9px 36px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 14px;
}

.react-datepicker__year-read-view {
  margin-left: 20px;
}

#invisible-elements {
  display: none;
}